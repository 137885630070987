import {useEffect, useState} from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import axios from "axios";
import {useParams} from "react-router-dom";

const WorkspaceAccounts = () => {
    const { workspace_id } = useParams();
    const [data, setData] = useState([]);

    useEffect( async() => {
            try {
                await axios.get("https://truva-api.basework.dev/api/admin/workspaces/"+workspace_id
                    ).then((resp) => {
                    setData(resp.data.data[0]);
                });
            } catch (error) {
                console.log('error', error);
            }
        }, [])

    const tableColumns = [
        {
            name: 'Account ID',
            selector: row => `#${row['id']}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Name',
            selector: row => `${row['first_name']} ${row['last_name']}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Account Role',
            selector: row => `${row['account_role']}`,
            sortable: false,
            center: true,
        }

    ];
    return (
        <>
            <Breadcrumbs mainTitle={`${data.name}'s Workspace`} parent="Workspace" title="List" />
            <Container fluid={true}>
                <Row>
                    <DataTable
                        data={data.workspaceAccounts}
                        columns={tableColumns}
                        striped={true}
                        center={true}
                        pagination
                        // selectableRows
                        // onSelectedRowsChange={handleRowSelected}
                        // clearSelectedRows={toggleDelet}
                    />
                </Row>
            </Container>
        </>
    );
};

export default WorkspaceAccounts;
