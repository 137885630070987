import {useEffect, useState} from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import axios from "axios";

const Workspace = () => {
    const [data, setData] = useState([]);

    useEffect( async() => {
            try {
                await axios.get("https://truva-api.basework.dev/api/admin/workspaces").then((resp) => {
                    setData(resp.data.data);
                });
            } catch (error) {
                console.log('error', error);
            }
        }, [])

    const tableColumns = [
        {
            name: 'ID',
            selector: row => `#${row['id']}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Name',
            selector: row => `${row['name']}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Projects',
            selector: row => <a href={`/workspaces/${row.id}/projects`}><i className="fa fa-eye"></i></a>,
            sortable: false,
            center: true,
        },
        {
            name: 'Accounts',
            selector: row => <a href={`/workspaces/${row.id}/accounts`}><i className="fa fa-eye"></i></a>,
            sortable: false,
            center: true,
        }

    ];
    return (
        <>
            <Breadcrumbs mainTitle="Workspaces" parent="Workspace" title="List" />
            <Container fluid={true}>
                <Row>
                    <DataTable
                        data={data}
                        columns={tableColumns}
                        striped={true}
                        center={true}
                        pagination
                        // selectableRows
                        // onSelectedRowsChange={handleRowSelected}
                        // clearSelectedRows={toggleDelet}
                    />
                </Row>
            </Container>
        </>
    );
};

export default Workspace;
