import React, { Fragment, useState, useEffect } from 'react';

const Loader = (props) => {
  const [show, setShow] = useState(false);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShow(false);
  //   }, 3000);
  //
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [show]);

  return (
    <Fragment>
      <div className={`loader-wrapper ${show ? '' : 'loderhide'}`}>
      </div>
    </Fragment>
  );
};

export default Loader;
