import {useEffect, useState} from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import axios from "axios";

const Accounts = () => {
    const [data, setData] = useState([]);

    useEffect( async() => {
            try {
                await axios.get("https://truva-api.basework.dev/api/admin/accounts").then((resp) => {
                    setData(resp.data.data);
                });
            } catch (error) {
                console.log('error', error);
            }
        }, [])

    const tableColumns = [
        {
            name: 'ID',
            selector: row => `#${row['id']}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Name',
            selector: row => `${row['first_name']} ${row['last_name']}`,
            sortable: true,
            center: false,
        },
        {
            name: 'E-mail',
            selector: row => row.email,
            sortable: true,
            center: true,
        },
        {
            name: 'Phone',
            selector: row => row.phone || 'N/A',
            sortable: false,
            center: true,
        },
        {
            name: 'Edit',
            selector: row => <a href={`/accounts/${row.id}`}><i className="fa fa-pencil"></i></a>,
            sortable: false,
            center: true,
        }

    ];
    return (
        <>
            <Breadcrumbs mainTitle="Accounts" parent="Account" title="List" />
            <Container fluid={true}>
                <Row>
                    <DataTable
                        data={data}
                        columns={tableColumns}
                        striped={true}
                        center={true}
                        pagination
                        // selectableRows
                        // onSelectedRowsChange={handleRowSelected}
                        // clearSelectedRows={toggleDelet}
                    />
                </Row>
            </Container>
        </>
    );
};

export default Accounts;
