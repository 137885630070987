import React, {useEffect, useState} from "react";
import {CardBody, CardFooter, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Breadcrumbs, Btn, H4} from "../../AbstractElements";
import DataTable from 'react-data-table-component';
import { useParams } from "react-router-dom";
import axios from "axios";
import {
    AboutMe,
    Address,
    City,
    Company, Country,
    EditProfile,
    EmailAddress,
    FirstName,
    LastName,
    PostalCode, UpdateProfile,
    Username, UsersCountryMenu
} from "../../Constant";
import {useForm} from "react-hook-form";

const Accounts = () => {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onEditSubmit = async() => {
        try {
            await axios.put(`https://truva-api.basework.dev/api/admin/accounts/${id}`,{
                first_name: data.first_name,
                last_name: data.last_name
            }).then((resp) => {
                alert(resp.data.message);
            });
        } catch (error) {
            console.log('error', error);
        }
    }

    const resetPassword = async () => {
        try {
            await axios.post(`https://truva-api.basework.dev/api/admin/accounts/${id}/reset-password`).then((resp) => {
                alert(resp.data.message);
            });
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect( async() => {
            try {
                await axios.get(`https://truva-api.basework.dev/api/admin/accounts/${id}`).then((resp) => {
                    setData(resp.data.data);
                });
            } catch (error) {
                console.log('error', error);
            }
        }, [])

    return (
        <>
            <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
                <CardHeader>
                    <H4 attrH4={{ className: "card-title mb-0" }}>{EditProfile}</H4>

                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm="6" md="6">
                            <FormGroup><Label className="form-label">First Name</Label>
                                <Input
                                    defaultValue={data.first_name}
                                    name="first_name"
                                    onChange={e => setData({...data, first_name: e.target.value})}
                                    className="form-control" type="text" placeholder="First Name" /><span style={{ color: "red" }}>{errors.FirstName && 'FirstName is required'} </span>
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="6">
                            <FormGroup><Label className="form-label">Last Name</Label>
                                <Input
                                    defaultValue={data.last_name}
                                    name="last_name"
                                    onChange={e => setData({...data, last_name: e.target.value})}
                                    className="form-control" type="text" placeholder="Last Name" /><span style={{ color: "red" }}>{errors.LastName && 'LastName is required'} </span>
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="6">
                            <FormGroup> <Label className="form-label">Email Adress</Label>
                                <Input
                                    defaultValue={data.email}
                                    name="email"
                                    disabled={true}
                                    onChange={e => setData({...data, email: e.target.value})}
                                    className="form-control" type="email" placeholder="Email"  /><span style={{ color: "red" }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
                            </FormGroup>
                        </Col>
                        <Col sm="6" md="6">
                            <FormGroup> <Label className="form-label">Phone</Label>
                                <Input
                                    defaultValue={data.phone}
                                    name="phone"
                                    disabled={true}
                                    onChange={e => setData({...data, phone: e.target.value})}
                                    className="form-control" type="tel" placeholder="Phone"  /><span style={{ color: "red" }}>{errors.EmailAddress && 'EmailAddress is required'} </span>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>

                <CardFooter className="text-end">
                    <a
                        onClick={resetPassword}
                        className="btn btn-danger"
                        href="#javascript"
                        >Reset Password</a>
                    <Btn attrBtn={{ color: "primary", type: "submit" }} >{UpdateProfile}</Btn>
                </CardFooter>
            </Form>
        </>
    );
};

export default Accounts;
