import Accounts from "../pages/account";
import Home from "../pages/home";
import AccountEdit from "../pages/account/edit";
import Workspace from "../pages/workspace";
import WorkspaceAccounts from "../pages/workspace/accounts";
import WorkspaceProjects from "../pages/workspace/project";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
export const routes = [
  {
    path: `/home`,
    Component: <Home />,
  },
  {
    path: `/accounts`,
    Component: <Accounts />,
  },
  {
    path: `/accounts/:id`,
    Component: <AccountEdit />,
  },
  {
    path: `/workspaces`,
    Component: <Workspace />,
  },
  {
    path: `/workspaces/:workspace_id/accounts`,
    Component: <WorkspaceAccounts />,
  },
  {
    path: `/workspaces/:workspace_id/projects`,
    Component: <WorkspaceProjects />,
  }
];
