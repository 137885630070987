import {CardBody, Col, Row} from "reactstrap";
import Account from "../account"
import Workspace from "../workspace";

const Home = () => {
    return (
        <>
            <CardBody>
                <Row>
                    <Col sm="6" md="6">
                        <Account/>
                    </Col>
                    <Col sm="6" md="6">
                        <Workspace/>
                    </Col>
                </Row>
            </CardBody>
        </>
    )
}

export default Home