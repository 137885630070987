import React from 'react';
import Routers from './Route';
import ProjectProvider from './_helper/Project/ProjectProvider';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';

const App = () => (
    <div className='App'>
      <CustomizerProvider>
        <ProjectProvider>
          <AnimationThemeProvider>
            <Routers />
          </AnimationThemeProvider>
        </ProjectProvider>
      </CustomizerProvider>
    </div>
);

export default App;
